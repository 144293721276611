//current list of cities
export const thaiCities = [
  "Bangkok",
  "Chiang Mai",
  "Phuket",
  "Pattaya",
  "Krabi",
  "Koh Samui",
  "Hua Hin",
  "Ayutthaya",
  "Kanchanaburi",
  "Nakhon Ratchasima",
  "Chonburi",
  "Udon Thani",
  "Surat Thani",
  "Ubon Ratchathani",
  "Trang",
  "Rayong",
  "Khon Kaen",
  "Phitsanulok",
  "Nakhon Si Thammarat",
  "Sukhothai",
  "Nong Khai",
  "Ranong",
  "Chumphon",
  "Samut Prakan",
  "Samut Sakhon",
  "Nakhon Pathom",
  "Songkhla",
  "Yala",
  "Satun",
  "Narathiwat",
  "Trat",
];

//list of fulltime incomes
export const fullTimeSalaries = [
  "at least THB 20k/mo.",
  "THB 30k/mo.",
  "THB 40k/mo.",
  "THB 50k/mo.",
  "THB 60k/mo.",
  "THB 70k/mo.",
  "THB 80k/mo.",
  "THB 90k/mo.",
  "over THB 100k/mo.",
];

export const blogCategories = [
  "Teaching",
  "Thai Life",
  "Learning Thai",
  "Current Events",
  "Sponsored",
  "How to",
  "Dos and Donts",
  "General",
  "Question",
];

//list of partime incomes.
export const partTimeSalaries = [
  "at least 250THB/hour",
  "THB 300/hour",
  "THB 400/hour",
  "THB 500/hour",
  "THB 600/hour",
  "THB 700/hour",
  "THB 800/hour",
  "THB 900/hour",
  "over THB 1000/hour",
];

export const coreJobRequirements = [
  "BA",
  "Master's",
  "BSc",
  "Certificate",
  "Diploma",
  "PGCE",
  "Phd",
  "bEd",
  "CELTA",
  "DELTA",
  "MBA",
  "TEFL",
];

export const yearsOfExperience = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
];

export const nationalities = [
  "British",
  "American",
  "Canadian",
  "Australian",
  "New Zealand",
  "South African",
  "Afghan",
  "Albanian",
  "Algerian",
  "Andorran",
  "Angolan",
  "Antiguans",
  "Argentinean",
  "Armenian",
  "Austrian",
  "Azerbaijani",
  "Bahamian",
  "Bahraini",
  "Bangladeshi",
  "Barbadian",
  "Barbudans",
  "Batswana",
  "Belarusian",
  "Belgian",
  "Belizean",
  "Beninese",
  "Bhutanese",
  "Bolivian",
  "Bosnian",
  "Brazilian",
  "Bruneian",
  "Bulgarian",
  "Burkinabe",
  "Burmese",
  "Burundian",
  "Cambodian",
  "Cameroonian",
  "Cape Verdean",
  "Central African",
  "Chadian",
  "Chilean",
  "Chinese",
  "Colombian",
  "Comoran",
  "Congolese",
  "Costa Rican",
  "Croatian",
  "Cuban",
  "Cypriot",
  "Czech",
  "Danish",
  "Djibouti",
  "Dominican",
  "Dutch",
  "East Timorese",
  "Ecuadorean",
  "Egyptian",
  "Emirian",
  "Equatorial Guinean",
  "Eritrean",
  "Estonian",
  "Ethiopian",
  "Fijian",
  "Filipino",
  "Finnish",
  "French",
  "Gabonese",
  "Gambian",
  "Georgian",
  "German",
  "Ghanaian",
  "Greek",
  "Grenadian",
  "Guatemalan",
  "Guinea-Bissauan",
  "Guinean",
  "Guyanese",
  "Haitian",
  "Herzegovinian",
  "Honduran",
  "Hungarian",
  "I-Kiribati",
  "Icelander",
  "Indian",
  "Indonesian",
  "Iranian",
  "Iraqi",
  "Irish",
  "Israeli",
  "Italian",
  "Ivorian",
  "Jamaican",
  "Japanese",
  "Jordanian",
  "Kazakhstani",
  "Kenyan",
  "Kittian and Nevisian",
  "Kuwaiti",
  "Kyrgyz",
  "Laotian",
  "Latvian",
  "Lebanese",
  "Liberian",
  "Libyan",
  "Liechtensteiner",
  "Lithuanian",
  "Luxembourger",
  "Macedonian",
  "Malagasy",
  "Malawian",
  "Malaysian",
  "Maldivian",
  "Malian",
  "Maltese",
  "Marshallese",
  "Mauritanian",
  "Mauritian",
  "Mexican",
  "Micronesian",
  "Moldovan",
  "Monacan",
  "Mongolian",
  "Moroccan",
  "Mosotho",
  "Motswana",
  "Mozambican",
  "Namibian",
  "Nauruan",
  "Nepalese",
  "Netherlander",
  "New Zealander",
  "Nicaraguan",
  "Nigerian",
  "Nigerien",
  "Niuean",
  "North Korean",
  "North Macedonian",
  "Norwegian",
  "Omani",
  "Pakistani",
  "Palauan",
  "Panamanian",
  "Papua New Guinean",
  "Paraguayan",
  "Peruvian",
  "Philippine",
  "Pitcairn Islander",
  "Polish",
  "Portuguese",
  "Puerto Rican",
  "Qatari",
  "Romanian",
  "Russian",
  "Rwandan",
  "Saint Lucian",
  "Salvadoran",
  "Sammarinese",
  "Samoan",
  "Sao Tomean",
  "Saudi Arabian",
  "Senegalese",
  "Serbian",
  "Seychellois",
  "Sierra Leonean",
  "Singaporean",
  "Slovak",
  "Slovene",
  "Solomon Islander",
  "Somali",

  "South Korean",
  "South Sudanese",
  "Spanish",
  "Sri Lankan",
  "Sudanese",
  "Surinamer",
  "Swazi",
  "Swedish",
  "Swiss",
  "Syrian",
  "Taiwanese",
  "Tajikistani",
  "Tanzanian",
  "Thai",
  "Togolese",
  "Tokelauan",
  "Tongan",
  "Trinidadian or Tobagonian",
  "Tunisian",
  "Turkish",
  "Turkmen",
  "Tuvaluan",
  "Ugandan",
  "Ukrainian",
  "Uruguayan",
  "Uzbekistani",
  "Vanuatuan",
  "Venezuelan",
  "Vietnamese",
  "Vincentian",
  "Wallisian",
  "Futunan",
  "Yemeni",
  "Zambian",
  "Zimbabwean",
];
